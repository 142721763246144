.body-container {
  /*background-size: cover;*/
  max-width: 100%;
  max-height: 100%;
  min-width: 375px;
  /*min-height: 768px;*/
  position: relative;
  margin:auto;
  overflow: hidden;
  overflow-y: hidden;
  font-family: 'ProximaNova-Regular', Verdana, arial, helvetica;
  color: #414549;
}

.token-container {
  /*background-size: cover;*/
  max-width: 100%;
  max-height: 100%;
  min-width: 375px;
  /*min-height: 768px;*/
  position: relative;
  margin:auto;
  overflow-y: auto;
  font-family: 'ProximaNova-Regular', Verdana, arial, helvetica;
  color: #414549;
}

.token-view {
  height: calc(100vh - 110px);
  overflow-y: auto;
}

div.header {
  min-height: 60px;
  background: #1C92C7;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
}

.margin-top {
  height: calc(100vh - 50px);
}

.odd {
  background-color: white;
}

.even {
  background-color: #f7f7f7;
}

i {
  position: relative;
  font-size: 16px;
  font-weight: bold;
}

.tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ddd;
  color: black;
  padding: 10px 20px;
  border-radius: 6px;
  white-space: normal;
  width: 100%;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  font-size: 14px;
  line-height: 1.5;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

i:hover + .tooltip {
  opacity: 1;
  visibility: visible;
}

.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}

.btn-info {
  background-color: #1C92C7;
  cursor: pointer;
}

.btn-info:hover {
  color: gray;
  background-color: #85d3fa;
  cursor: pointer;
}

.btn-info.disabled, .btn-info:disabled {
  color: #999;
  border-color: #999;
  background-color: #dddddd;
  cursor: default;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #999;
  background: #dddddd;
  box-shadow: none;
  outline-color: none;
  cursor: default;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  color: #999;
  background: #dddddd;
  box-shadow: none;
  outline-color: none;
  cursor: default;
}

.btn-outline-info {
  color: #1C92C7;
  border-color: #1C92C7;
  cursor: pointer;
}

.btn-outline-info:hover {
  color: gray;
  background-color: #85d3fa;
  border-color: #1C92C7;
  cursor: pointer;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #999;
  border-color: #999;
  background-color: #dddddd;
  cursor: default;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: gray;
  background-color: #85d3fa;
  border-color: #85d3fa;
  box-shadow: none;
  outline-color: none;
  cursor: default;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
  outline-color: none;
  color: gray;
  background-color: #85d3fa;
  border-color: #dddddd;
  cursor: default;
}

.header-options {
  position: fixed;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-1 {
  opacity: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.drawer {
  height: 100%;
  background: white;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1;
  top: 60;
  left: 0;
  width: 70px;
  max-width: 300px;
  transition: width 0.3s ease-out;
}

.drawer.open {
  width: 250px;
}

.table-holder {
    height: calc(100vh - 220px);
    display: block;
    position: absolute;
    width: 100%;
}

.searh-bar.open + .table-holder {
  height: calc(100% - 360px);
}

.searh-bar {
  width: 100%;
  height: 30px;
  transition: height 0.3s ease-in;
}

.searh-bar.open {
  height: 220px;
}

.card {
  margin-left: auto;
  margin-right: auto;
}

.body-width {
  width: 100%;
  height: calc(100vh - 60px);
  position: relative;
  transition: margin-left 0.3s ease-out;
}

.header-heigth {
    min-height: 60px;
}

.no-login-header {
    min-height: 60px;
}

.body-container {
  width: 100%;
  height:  calc(100vh - 50px);
  min-height: 250px;
  position: relative;
  overflow-y: hidden;
}

.body-width.open {
  width: 100%;
  margin-left: 250px;
}

.edit-view.open {
  margin-left: calc(100vw - 82vw - 250px);
}

.topright-div.open {
  margin-right: 260px;
}

.edit-view {
  display:block;
  width: 100vw;
  min-width: 1650px;
  min-height: 650px;
  margin-left: calc(100vw - 82vw - 50px);
  overflow: auto;
  height: calc(100vh - 200px);
  transition: margin-left 0.3s ease-out;
}

.drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.row {
  margin: 0px; 
}

.login-img {

}

.row-login {
  min-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
}

.row-login img { 
    width:100%;
    height:100%; 
    object-fit:cover;
}

.drawer li {
  margin: 0.5rem 0;
}

.drawer a {
  color: black;
  text-decoration: none;
  font-size: 1.2rem;
}

.drawer li:hover {
  background-color: #e9ecef;
}

.drawer drawer-li:hover {
  background-color: white;
}

.header-span:hover {
  cursor: pointer;
  background-color: #65a4c4;
  color: white;
}

.header-span {
  color: white;
}

.header-span-disabled:hover {
  cursor: default;
  color: #c1c1c1;
}

.header-span-disabled {
  cursor: default;
  color: #c1c1c1;
}

.dropdown-item-disabled {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  background-color: transparent; 
  text-align: inherit;
  white-space: nowrap;
  border: 0;
  cursor: none;
}

.dropdown-item-body-disabled {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #999;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
  cursor: default;
}

.fixed_header{
  table-layout: fixed;
  border-collapse: collapse;
  height: 70vh;
}

.fixed_header tbody{
  display:block;
  overflow: auto;
  height: 100%;
}

.fixed_header thead tr {
   display: block;
}

.fixed_header thead {
  background: gray;
  color:#fff;
}

.fixed_header_div {
  text-align: center;
  background: white;
  color: #696765;
  min-height: 34px;
}

.fixed_header_div div {
  padding-top: 4px;
  border-width: 1px;
  border-style: solid double solid none;
  border-color: #b5b1af;
}

.fixed_div {
  display:block;
  overflow: auto;
  height: calc(100% - 40px);
  max-height: 100%;
  overflow-y: overlay;
}

.fixed_div_confidants {
  display: block;
  overflow-y: auto;
  max-height: 250px;
  overflow-y: overlay;
}

.fixed_header th, .fixed_header td {
  padding: 5px;
  text-align: left;
}

.body_div {
  height: calc(100% - 200px);
}

.edit-form-view {
  height: calc(100% - 200px);
}

.FlexItem {
  height: calc(100vh - 60px);
  width: 66.6%;
  flex: 0 1 auto;
}

.FlexItem img {
  display: block;
  width: 100%;
}

.login-input-vertical-align {
  padding-top: 10%;
}

.tableFixHead {
  table-layout: fixed;
  border-collapse: collapse;
}
  .tableFixHead tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 165px)
}
.tableFixHead thead tr {
  display: block;
}
.tableFixHead th,
.tableFixHead  td {
  padding: 5px 10px;
}

.bottomright {
  position: fixed;
  font-size: 18px;
  color: grey;
  margin-top: 20px;
  right: -30px;
  bottom: 10px;
  min-width: 300px;
}

.bottomright-ie {
  position: absolute;
  bottom: 5px;
  font-size: 18px;
  color: grey;
  margin-top: 20px;
  right: 25px;
}

.topright {
  position: absolute;
  top: 10px;
  right: 5px;
  font-size: 18px;
}

.topright-div {
  position: absolute;
  top: 65px;
  right: 0px;
  margin-right: 60px;
  transition: margin-right 0.3s ease-out;
}

.buttons-top-div {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.status-placeholder {
  padding-top: 5px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  list-style: none;
  height: 38px;
}

.bg-green {
  background-color: #e7ffdc;
  color: green;
}

.bg-grey {
  background-color: #d2d2d2;
  color: grey;
}

.bg-red {
  background-color: #efb4af;
  color: red;
}

.dropzone {
    background: #f7f5f3;
    border-radius: 5px;
    border: 2px dashed #1c92c7;
    border-image: none;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    vertical-align: middle;
}

.loading-div-inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  font-size: 10em;
  color: white;
}

.loading-div-inner-confirm {
  position: relative;
  left: 50%;
  top: 49%;
  transform: translate(-50%, -50%);
}

.loading-p-inner {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  padding: 10px;
  color: white;
}

.loading-p-inner-one {
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
  padding: 10px;
  color: white;
}

.popup-dark {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  background-color: rgba(0,0,0, 0.8);
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}

.popup_inner {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 350px;
  height: 345px;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
  font-family: 'ProximaNova-Regular', Verdana, arial, helvetica;
}

.popup_inner_about {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  max-width: 400px;
  /*height: 235px;*/
  position: relative;
  margin: 0 auto;
  top: 25%;
  background: white;
  overflow: hidden;
  color: #555555;
  font-family: 'ProximaNova-Regular', Verdana, arial, helvetica;
}

.popup_inner_import {
  /*position: absolute;*/
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  max-width: 400px;
  /*height: 235px;*/
  /*position: relative;*/
  margin: 10% auto;
  /*top: 25%;*/
  background: white;
  overflow: hidden;
  font-family: 'ProximaNova-Regular', Verdana, arial, helvetica;
}

.popup_inner_user_info {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: auto;
  height: auto;
  max-width: 800px;
  min-height: 480px;
  /*position: relative;*/
  margin: 10% auto;
  background: white;
  overflow: hidden;
  font-family: 'ProximaNova-Regular', Verdana, arial, helvetica;
}

.popup_inner_cert_info {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: auto;
  height: auto;
  max-width: 800px;
  /*max-height: 500px;*/
  /*position: relative;*/
  margin: 10% auto;
  background: white;
  overflow: hidden;
  font-family: 'ProximaNova-Regular', Verdana, arial, helvetica;
}

.popup_inner_2f {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 360px;
/*  height: 345px;*/
  left: 25%;
  right: 25%;
  top: 25%;
  /*bottom: 25%;*/
  margin: auto;
  background: white;
  font-family: 'ProximaNova-Regular', Verdana, arial, helvetica;
}

.overflow-y {
  overflow-y: scroll;
  max-height: 500px;
}

.popup_inner_user_pin {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: auto;
  height: auto;
  max-width: 400px;
  /*min-height: 440px;*/
  /*position: relative;*/
  margin: 10% auto;
  background: white;
  overflow: hidden;
  font-family: 'ProximaNova-Regular', Verdana, arial, helvetica;
}

.popup_inner_user_token_pin {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: auto;
  height: auto;
  max-width: 400px;
  min-height: 140px;
  position: relative;
  left: 50%;
  top: 49%;
  transform: translate(-50%, -50%);
  background: white;
  overflow: hidden;
  font-family: 'ProximaNova-Regular', Verdana, arial, helvetica;
}

.popup_inner_token_confidants {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: auto;
  height: auto;
  max-width: 430px;
  min-height: 140px;
  position: relative;
  left: 50%;
  top: 49%;
  transform: translate(-50%, -50%);
  background: white;
  overflow: hidden;
  font-family: 'ProximaNova-Regular', Verdana, arial, helvetica;
}

.popup_inner_user_token_pin_confirm {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: auto;
  height: auto;
  max-width: 400px;
  min-height: 160px;
  background: white;
  overflow: hidden;
  font-family: 'ProximaNova-Regular', Verdana, arial, helvetica;
}

.popup_inner_error {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 350px;
  height: 395px;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
  font-family: 'ProximaNova-Regular', Verdana, arial, helvetica;
}

.popup_inner_key {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  max-width: 400px;
  max-height: 750px;
  height: auto;
  margin: 10% auto;
  background: white;
  overflow: hidden;
  font-family: 'ProximaNova-Regular', Verdana, arial, helvetica;
}

.popup_inner_key_gen {
  max-height: 200px;
  overflow-y: scroll;
}

.user_info_roles {
  min-height: 185px;
}

.table-border {
    border-radius: 5px 5px 5px;
    border: 2px solid rgb(28, 146, 199);
    border-image: none;
    max-width: 100%;
    padding-top: 7px;
    padding-bottom: 7px;
}

.table-border-blocked {
    border-radius: 5px 5px 5px;
    border: 2px solid #b5b1af;
    border-image: none;
    max-width: 100%;
    padding-top: 7px;
    padding-bottom: 7px;
    background-color: #eaeaea9e;
}

.table-header {
    border-width: 1px;
    border-style: none none solid none;
    border-color: #b5b1af;
    color: #b5b1af;
    min-height: 30px;
    text-align: center;
}

.bg-color {
  background-color: #f7f5f3;
}

.bg-color-active {
  background-color: #f3f8fd;
}

.pointer {
  cursor: pointer;
}

.margin-left-5 {
  margin-left: 0px;
}

.min-heigth-33 {
  min-height: 33px;
}

.active-token-img {
  transform: rotateY(180deg);
}

.return-div {
  display: flex;
  align-items: center;
}

.return-text {
  vertical-align: middle;
}

.popup-info {
  position: fixed;
  z-index: 5;
  right: 0;
  bottom: 0;
  margin: auto;
}

.popup-info-inner-arr {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 300px;
  margin: auto;
  background: white;
  overflow-y: auto;
  margin-bottom: 15px;
  margin-right: 15px;
}

.popup-info-inner {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 300px;
  bottom: 15px;
  right: 15px;
  margin: auto;
  background: white;
  overflow-y: auto;
}

.message-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 110;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
}

.message-popup-inner {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    width: 400px;
    max-height: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15%;
    background: white;
    overflow: hidden;
}

.card-height {
  overflow-y: scroll;
  max-height: 500px;
}

.popup_inner_confirm {
  /*position: absolute;*/
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  max-width: 350px;
  position: relative;
  left: 50%;
  top: 49%;
  transform: translate(-50%, -50%);
  background: white;
  overflow-y: auto;
}

.success {
  background-color: #D9FFDA;
}

.failed {
  background-color: #FFE3E3;
}

.fadein-popup div {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

.fadeout-popup div {
    -webkit-animation: fadeout 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeout 1s; /* Firefox < 16 */
        -ms-animation: fadeout 1s; /* Internet Explorer */
         -o-animation: fadeout 1s; /* Opera < 12.1 */
            animation: fadeout 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

.btn-cog {
  padding-left: 0px;
  padding-right: 0px;
}

@media (max-width: 767px) {
  .user_info_roles {
    min-height: 0px;
  }
  .table-header {
    border-style: none none none none;
  }
  .margin-left-5 {
    margin-left: 5px;
  }
  .min-heigth-33 {
    min-height: 0px;
    height: 0px;
  }
  .display-none {
    display: none;
  }
}

@media (min-width: 576px) {
  .login-img {
    background-color: #f0ebe6;
  }
}

@media (max-width: 575px) {
.disable-img {
    display: none;
  }
}

@media (max-width: 575px) {
.display-none {
    display: none;
  }
.col-xs {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xs-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xs-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xs-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xs-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xs-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xs-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xs-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xs-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xs-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xs-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xs-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xs-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xs-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xs-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xs-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xs-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xs-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xs-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xs-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.ccol-1 {
  -ms-flex: 0 0 5%;
  flex: 0 0 5%;
  max-width: 5%;
}

.ccol-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.ccol-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.ccol-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.ccol-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.cccol-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.ccol-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.ccol-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.ccol-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.ccol-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.ccol-11 {
  -ms-flex: 0 0 94.5%;
  flex: 0 0 94.5%;
  max-width: 94.5%;
}

.ccol-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 992px) {
  .ccol-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-ccols-lg-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-ccols-lg-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-ccols-lg-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-ccols-lg-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-ccols-lg-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-ccols-lg-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .ccol-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .ccol-lg-1 {
    -ms-flex: 0 0 5%;
    flex: 0 0 5%;
    max-width: 5%;
  }
  .ccol-lg-2 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .ccol-lg-3 {
    -ms-flex: 0 0 21.6%;
    flex: 0 0 21.6%;
    max-width: 21.6%;
  }
  .ccol-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .ccol-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .ccol-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ccol-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .ccol-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .ccol-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ccol-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .ccol-lg-11 {
    -ms-flex: 0 0 94.5%;
    flex: 0 0 94.5%;
    max-width: 94.5%;
  }
  .ccol-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.blocked {
  background-color: #eaeaea9e
}

.padding-left {
  padding-left: 0px;
}

.padding-left-sm {
  padding-left: 0px;
}

.searh-bar-max-height {
  max-height: 100%;
}

@media (max-width: 991px) {
  .searh-bar.open {
    height: 290px;
  }
  .searh-bar.open + .table-holder {
    height: calc(100% - 480px);
  }
}

@media (min-width: 768px) {
  .display-none-min {
    display: none;
  }

  .padding-left-sm {
    padding-left: 15px;
  }
}

@media (max-width: 767px) {
  .searh-bar.open {
    height: 290px;
  }
  .searh-bar.open + .table-holder {
    height: calc(100% - 420px);
  }

  .padding-left-sm {
    padding-left: 15px;
  }

  .padding-left {
    padding-left: 15px;
  }
}

@media (max-width: 575px) {
  .searh-bar-max-height {
    display:block;
    overflow: auto;
    height: 300px;
    max-height: 100%;
    overflow-y: overlay;
    width: 100%
  }

  .searh-bar.open {
    height: 400px;
  }

  .searh-bar.open + .table-holder {
    height: calc(100% - 560px);
  }

  .padding-left {
    padding-left: 15px;
  }

  .padding-left-sm {
    padding-left: 15px;
  }
}

@media (max-width: 545px) {
  .topright {
    top: 50px;
  }
  .header-heigth {
    min-height: 95px;
  }
  .table-holder {
    height: calc(100vh - 240px);
  }
  .token-view {
    height: calc(100vh - 150px);
  }

  .searh-bar-max-height {
    display:block;
    overflow: auto;
    height: 300px;
    max-height: 100%;
    overflow-y: overlay;
    width: 100%
  }

  .searh-bar.open {
    height: 400px;
  }

  .padding-left {
    padding-left: 15px;
  }
}

.abc-checkbox label::after {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 2px;
  margin-left: -1.25rem;
  padding-left: 2px;
  padding-top: 3px;
  font-size: 11px;
  color: #495057;
}

.no-left-right-margin {
  padding-left: 0;
  padding-right: 0;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: local('ProximaNova-Regular'), url(../fonts/12093.ttf) format('truetype');
}